const am = angular.module('mtz-directives');

am.factory('mtzProfileSvc', [
  '$http', 'rest',
  ($http, rest) => {
    const svc = {};

    svc.getProfile = (getPaxService) => {
      // tilde (~) is the current paxId
      return $http.get(rest.getRestUrlPrefix() + getPaxService + '~');
    };

    svc.getSysUserId = (getSysUserService) => {
      return $http.get(rest.getRestUrlPrefix() + getSysUserService);
    };

    svc.updateSysUser = (updateSysUserService, sysUserDTO) => {
      updateSysUserService = updateSysUserService.replace("sysUserId", sysUserDTO.sysUserId);
      const url = rest.getRestUrlPrefix() + updateSysUserService;
      return $http.put(url, sysUserDTO);
    };

    svc.savePersonalInfo = (savePaxService, paxDTO) => {
      const url = rest.getRestUrlPrefix() + savePaxService + '~';
      return $http.put(url, paxDTO);
    };

    svc.getAddress = (getPaxAddressService) => {
      return $http.get(rest.getRestUrlPrefix() + getPaxAddressService);
    };

    svc.getPhone = (getPaxPhoneService) => {
      return $http.get(rest.getRestUrlPrefix() + getPaxPhoneService);
    };

    svc.saveAddressInfo = (saveAddressService, addressDTO) => {
      saveAddressService = saveAddressService.replace("addressTypeCode", addressDTO.addressTypeCode);
      const url = rest.getRestUrlPrefix() + saveAddressService;
      return $http.put(url, addressDTO);
    };

    svc.insertAddressInfo = (insertAddressService, addressDTO) => {
      const url = rest.getRestUrlPrefix() + insertAddressService;
      return $http.post(url, addressDTO);
    };

    svc.savePhoneInfo = (savePhoneService, phoneDTO) => {
      savePhoneService = savePhoneService.replace("phoneTypeCode", phoneDTO.phoneTypeCode);
      const url = rest.getRestUrlPrefix() + savePhoneService;
      return $http.put(url, phoneDTO);
    };

    svc.insertPhoneInfo = (insertPhoneService, phoneDTO) => {
      const url = rest.getRestUrlPrefix() + insertPhoneService;
      return $http.post(url, phoneDTO);
    };

    svc.getEmailAddress = (getPaxEmailAddressService) => {
      return $http.get(rest.getRestUrlPrefix() + getPaxEmailAddressService);
    };

    svc.updateEmailAddress = (updateEmailAddressService, emailDTO) => {
      updateEmailAddressService = updateEmailAddressService.replace("emailTypeCode", emailDTO.emailTypeCode);
      const url = rest.getRestUrlPrefix() + updateEmailAddressService;
      return $http.put(url, emailDTO);
    };

    svc.insertEmailAddress = (insertEmailAddressService, emailDTO) => {
      const url = rest.getRestUrlPrefix() + insertEmailAddressService;
      return $http.post(url, emailDTO);
    };

    svc.sendEmailValidationCode = (createEmailValidationCodeService, newEmailAddressDTO) => {
      const url = rest.getRestUrlPrefix() + createEmailValidationCodeService;
      return $http.post(url, newEmailAddressDTO);
    };

    svc.validateEmailCode = (emailValidationService, emailAddress, code) => {
      const url = rest.getRestUrlPrefix() + emailValidationService +
        '?emailAddress=' + emailAddress + '&verificationCode=' + code;
      return $http.get(url);
    };

    svc.changePassword = (changePasswordService, sysUserDTO, changePasswordDTO) => {
      changePasswordService = changePasswordService.replace("sysUserId", sysUserDTO.sysUserId);
      const url = rest.getRestUrlPrefix() + changePasswordService;
      return $http.put(url, changePasswordDTO);
    };

    svc.getCompaniesWorkFor = (getCompaniesWorkForService) => {
      return $http.get(rest.getRestUrlPrefix() + getCompaniesWorkForService);
    };

    svc.savePreferredCompany = (savePreferredCompanyService, roleDTO) => {
      savePreferredCompanyService = savePreferredCompanyService.replace('employmentId', roleDTO[0].employmentId);
      savePreferredCompanyService = savePreferredCompanyService.replace('employmentRoleId', roleDTO[0].id);
      const url = rest.getRestUrlPrefix() + savePreferredCompanyService;
      return $http.put(url, roleDTO[0]);
    };

    svc.getEmailUnsubscribeStatus = (getEmailUnsubscribeService) => {
      return $http.get(rest.getRestUrlPrefix() + getEmailUnsubscribeService);
    };

    svc.emailUnsub = (emailUnsubscribeService, paxMiscDTO) => {
      const url = rest.getRestUrlPrefix() + emailUnsubscribeService;
      return $http.post(url, paxMiscDTO);
    };

    svc.emailResub = (emailResubscribeService) => {
      emailResubscribeService = emailResubscribeService.replace('paxMiscId', sessionStorage.paxMiscId);
      const url = rest.getRestUrlPrefix() + emailResubscribeService;
      return $http.delete(url);
    };

    svc.getEmailVerification = (getEmailVerification) => {
      const url = rest.getRestUrlPrefix() + getEmailVerification;
      return $http.get(url);
    };

    svc.deleteEmailVerification = (deleteEmailVerification) => {
      deleteEmailVerification = deleteEmailVerification.replace('paxMiscId', sessionStorage.paxMiscId);
      const url = rest.getRestUrlPrefix() + deleteEmailVerification;
      return $http.delete(url);
    };

    svc.getTermsAndConditions = (termsAndConditionsService) => {
      const url = rest.getRestUrlPrefix() + termsAndConditionsService + "/ca";
      return $http.get(url);
    };

    svc.saveTermsAndConditions = (termsAndConditionsService, tncDTO) => {
      const url = rest.getRestUrlPrefix() + termsAndConditionsService;
      return $http.post(url, tncDTO);
    };

    svc.lockUserAccount = () => {
      return $http.post(rest.getRestUrlPrefix() + 'password/lock-account');
    };

    svc.logoutForcibly = () => {
      return $http.post(rest.getRestUrlPrefix() + 'authenticate/logout');
    };

    svc.getLanguagePreference = () => {
      return $http.get(rest.getRestUrlPrefix() + 'participants/~/attributes?search=vfName==LANGUAGEPREFERENCE');
    };

    svc.insertLanguagePreference = (paxMiscDTO) => {
      const url = rest.getRestUrlPrefix() + 'participants/~/attributes/';
      return $http.post(url, paxMiscDTO);
    };

    svc.updateLanguagePreference = (paxMiscDTO, paxMiscId) => {
      const url = rest.getRestUrlPrefix() + 'participants/~/attributes/' + paxMiscId;
      return $http.put(url, paxMiscDTO);
    };

    svc.getReportsAvailable = () => {
      return $http.get(rest.getRestUrlPrefix() + 'reports/getAllReports');
    };

    return svc;
  }]
);

am.controller('mtzProfileCtrl', [
  '$scope', 'dialogSvc', 'mtzProfileSvc', 'L10nFilter', '$window', '$state', '$location', 'googleAnalyticsSvc', 'loadLanguage', 'viewSvc',
  ($scope, dialogSvc, mtzProfileSvc, l10nFilter, $window, $state, $location, googleAnalyticsSvc, loadLanguage, viewSvc) => {

    if (!sessionStorage.loggedIn) {
      $state.go('login');
    }
    //Wait until the element exists
    angular.element(document).ready(function(){
    	if(sessionStorage.languagePreference === 'fr'){
    		angular.element("#epTipIcon").attr("class","ep-tip-icon-fr");
    	}else{
    		angular.element("#epTipIcon").attr("class","ep-tip-icon");
    	}
    });

    //This is to fix the powerbi code that hides the scrollbar on overflow, if they were on 
    //the powerbi reports and then click back to the home page the scroll bar was disappearing.
    var scrollbarStatus = angular.element(document.querySelector('body'));
    scrollbarStatus.css('overflow', 'visible');

    $scope.required = true;
    $scope.account = {};
    $scope.accountUnSub = false;
    $scope.personalInfo = {};
    $scope.company = {};
    $scope.passwordInvalid = '';
    $scope.paxDTO = {};
    $scope.sysUserDTO = {};
    $scope.addressDTO = {};
    $scope.phoneDTO = {};
    $scope.languagePreference = sessionStorage.languagePreference;
    $scope.emailAddressDTO = {};
    $scope.paxMiscDTO = {};
    $scope.sysUserName = "";
    $scope.tncChecked = false;
    $scope.firstTimeLogin = sessionStorage.firstTimeLogin === 'true';
    $scope.username = sessionStorage.username;
    $scope.preferredCompany = "";
    let blockedDomains = [".ru", ".cc", ".cn"];
    let resetPasswordCount = 0;
    $scope.addressRegex = '[A-Za-z0-9.,\'&@/\(\):;# -]*';
    $scope.nameRegex = '[A-Za-z0-9.,\' -]*';

    $scope.changeTab = (tab) => {
      $scope.view_tab = tab;
      //Google Analytics Event Tracking
      let eventAction = 'Tab Click';
      let tabName = '';
      switch (tab) {
        case 'tab1':
          tabName = 'Email & Password Tab';
          break;
        case 'tab2':
          tabName = 'Personal Tab';
          break;
        case 'tab3':
          tabName = 'Company Tab';
          break;
        case 'tab4':
          tabName = 'Terms & Conditions Tab';
          break;
      }
      let eventParameters = {
        'event_category': 'ProfileTabClick',
        'event_label': 'Profile Tab Clicked: ' + tabName
      };
      googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
      //End Google Analytics Event Tracking
    };

    if (!$scope.view_tab) {
      $scope.view_tab = 'tab1';
    }

    loadProfile();
    viewSvc.show();

    function loadProfile() {
      mtzProfileSvc.getProfile($scope.getPaxService).then(result => {
        $scope.paxDTO = result.data;
      },
        res => dialogSvc.showError('getProfile Failed', res.data[0].message));

        mtzProfileSvc.getSysUserId($scope.getSysUserService).then(result => {
          for (var i = 0; i < result.data.length; i++) {
            if (result.data[i].sysUserName === sessionStorage.username) {
              $scope.sysUserDTO.sysUserId = result.data[i].sysUserId;
              $scope.sysUserDTO.sysUserName = result.data[i].sysUserName;
              $scope.sysUserDTO.statusTypeCode = result.data[i].statusTypeCode;
            }
          }
        },
        res => dialogSvc.showError('getSysUserId Failed', res.data[0].message));

        mtzProfileSvc.getAddress($scope.getPaxAddressService).then(addressResult => {
        if (addressResult.data.length === 0) {
          $scope.noAddress = true;
        }
        else {
          $scope.noAddress = false;
          $scope.addressDTO = addressResult.data[0];
        }
      },
        res => dialogSvc.showError('getAddress Failed', res.data[0].message)
      );

      //first see if the language pax misc record exists for this person
      mtzProfileSvc.getLanguagePreference().then(result => {
        for (var i = 0; i < result.data.items.length; i++) {
          if (result.data.items[i].name === "LANGUAGEPREFERENCE") {
            sessionStorage.languagePreference = result.data.items[i].data;
            $scope.languagePreference = result.data.items[i].data;
            $scope.paxMiscId = result.data.items[i].id;
          }
        }
      });

      mtzProfileSvc.getTermsAndConditions($scope.termsAndConditionsService).then(resultTandC => {
        if (resultTandC.data.hasAcceptedTermsAndConditions === true) {
          sessionStorage.termsAndConditionsCheck = true;
          $scope.tncChecked = true;
          $scope.tncSaved = true;
        }
      })
      .catch(() => {
        sessionStorage.termsAndConditionsCheck = false;
        $scope.tncChecked = false;
        $scope.tncSaved = false;
      });

      mtzProfileSvc.getPhone($scope.getPaxPhoneService).then(phoneResult => {
        if (phoneResult.data.length === 0) {
          $scope.noPhone = true;
        }
        else {
          $scope.noPhone = false;
          $scope.phoneDTO = phoneResult.data[0];
        }
      },
        res => dialogSvc.showError('getPhone Failed', res.data[0].message)
      );

      mtzProfileSvc.getEmailAddress($scope.getPaxEmailAddressService).then(emailAddressResult => {
        if (emailAddressResult.data.length === 0) {
          $scope.noEmailAddress = true;
          $scope.emailRequired = true;
        }
        else {
          $scope.noEmailAddress = false;
          $scope.emailRequired = false;
          $scope.emailAddressDTO = emailAddressResult.data[0];
        }
      },
        res => dialogSvc.showError('getEmailAddress Failed', res.data[0].message)
      );

      mtzProfileSvc.getEmailUnsubscribeStatus($scope.getEmailUnsubscribeService).then(result => {
        $scope.unSubChecked = false;
        for (var i = 0; i < result.data.items.length; i++) {
          if (result.data.items[i].name === "UNSUBSCRIBED" && result.data.items[i].data === "Y") {
            sessionStorage.paxMiscId = result.data.items[i].id;
            $scope.unSubChecked = true;
          }
        }
      });

      // mtzProfileSvc.getLanguagePreference().then(result => {
      //   $scope.languagePreference = '';
      //   for (var i = 0; i < result.data.items.length; i++) {
      //     if (result.data.items[i].name === "LANGUAGEPREFERENCE") {
      //       sessionStorage.languagePreference = result.data.items[i].value;
      //       $scope.languagePrefence = result.data.items[i].value;
      //     }
      //   }
      // });

      mtzProfileSvc.getCompaniesWorkFor($scope.getCompaniesWorkForService).then(companiesResult => {
        $scope.companyDTO = companiesResult.data;
        for (var i = 0; i < $scope.companyDTO.length; i++) {
          if ($scope.companyDTO[i].addresses[0].preferred === "Y") {
            $scope.preferredCompany = $scope.companyDTO[i].companyName;
          }
        }
      },
        res => dialogSvc.showError('getCompaniesWorkForService Failed', res.data[0].message)
      );

    }

    if (sessionStorage.passwordChangeRequired) {
      $scope.passwordChangeRequired = JSON.parse(sessionStorage.passwordChangeRequired);
    }

    if (!$scope.view_tab) {
      $scope.view_tab = sessionStorage.profileTab;
    }

    $scope.$on('update-profile-tab', () => {
      $scope.view_tab = sessionStorage.profileTab;
    });

    $scope.showEmailValidationBox = false;

    $scope.emailUnsub = () => {
      //Google Analytics Event Tracking
      let eventAction = 'Email Unsubscribe';
      let eventParameters = {
        'event_category': 'Email Unsubscribe',
        'event_label': 'Email Unsubscribed '
      };
      googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
      //End Google Analytics Event Tracking

      if (angular.element("#checkbox1").prop("checked")) {
        $scope.paxMiscDTO.data = "Y";
        $scope.paxMiscDTO.date = new Date();
        $scope.paxMiscDTO.name = "UNSUBSCRIBED";
        $scope.paxMiscDTO.ownerId = sessionStorage.paxId;
        mtzProfileSvc.emailUnsub($scope.emailUnsubscribeService, $scope.paxMiscDTO).then(() => {
	      	  dialogSvc.showMessage(l10nFilter('email-unsubscribe', null, true));
        });
      }
      else {
        mtzProfileSvc.getEmailUnsubscribeStatus($scope.getEmailUnsubscribeService).then(result => {
          for (var i = 0; i < result.data.items.length; i++) {
            if (result.data.items[i].name === "UNSUBSCRIBED" && result.data.items[i].data === "Y") {
              sessionStorage.paxMiscId = result.data.items[i].id;
            }
          }
          mtzProfileSvc.emailResub($scope.emailResubscribeService).then(() => {
            //Google Analytics Event Tracking
            let eventAction = 'Email Resubscribed';
            let eventParameters = {
              'event_category': 'Email Resubscribed',
              'event_label': 'Email Resubscribed '
            };
            googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
            //End Google Analytics Event Tracking
            	dialogSvc.showMessage(l10nFilter('email-subscribe', null, true));
          });
        });
      }
    };

    $scope.saveTNC = () => {
      const date = new Date();
      const tncDTO = {
        'isEnrolled': true,
        'hasAcceptedTermsAndConditions': true,
        'enrollDate': date,
        'termsAndConditionsDate': date
      };

      mtzProfileSvc.saveTermsAndConditions($scope.termsAndConditionsService, tncDTO).
      then(() => {
        sessionStorage.termsAndConditionsCheck = true;
        $scope.tncChecked = true;
        $scope.tncSaved = true;
        //Google Analytics Event Tracking
        let eventAction = 'T&C Accepted';
        let eventParameters = {
          'event_category': 'T&C Accepted',
          'event_label': 'Terms and Conditions Accepted'
        };
        googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
        //End Google Analytics Event Tracking
        dialogSvc.showMessage(l10nFilter('terms-and-conditions-saved', null, true));

        if ((!sessionStorage.passwordChangeRequired || sessionStorage.passwordChangeRequired === 'false') && $scope.personalInfoForm.$valid && $scope.companyForm.$valid && $scope.tncForm.$valid) {
          //update sysuser to active
          mtzProfileSvc.getSysUserId($scope.getSysUserService).then(result => {
            for (var i = 0; i < result.data.length; i++) {
              if (result.data[i].sysUserName === sessionStorage.username) {
                $scope.sysUserDTO.sysUserId = result.data[i].sysUserId;
                $scope.sysUserDTO.sysUserName = result.data[i].sysUserName;
                $scope.sysUserDTO.statusTypeCode = 'ACTIVE';

                /* jshint ignore:start */
                mtzProfileSvc.updateSysUser($scope.updateSysUserService, $scope.sysUserDTO).then( () => {
                  console.log('SysUser Record Updated...');
                },
                  res2 => console.log('updateSysUserId Failed', res2.data[0].message));
                /* jshint ignore:end */
              }
            }
          },
            res => console.log('getSysUserId Failed', res.data[0].message));
        }
      }, () => {
        dialogSvc.showError('Error', 'Something went wrong. Try resubmitting.');
      });
    };

    $scope.saveAccount = () => {
      if (($scope.sysUserDTO.statusTypeCode === 'ACTIVE' && $scope.account.currentPassword && $scope.account.currentPassword.length > 0) ||
        ($scope.account.newPassword.length > 0 && $scope.account.newPassword2.length > 0) &&
        ($scope.account.newPassword === $scope.account.newPassword2)) {
        $scope.sysUserDTO.password = $scope.account.newPassword;
        $scope.sysUserDTO.paxId = $scope.paxDTO.paxId;
        $scope.sysUserDTO.sysUserId = $scope.sysUserDTO.sysUserId;
        $scope.sysUserDTO.statusDate = new Date();
        $scope.sysUserDTO.sysUserName = $scope.sysUserName;
        $scope.sysUserDTO.sysUserPasswordDate = new Date();
        let changePasswordDTO = {};
        changePasswordDTO.oldPassword = $scope.account.currentPassword;
        changePasswordDTO.newPassword = $scope.account.newPassword;
        mtzProfileSvc.changePassword($scope.changePasswordService, $scope.sysUserDTO, changePasswordDTO)
          .then(() => {
            $scope.account.currentPassword = null;
            $scope.account.newPassword = null;
            $scope.account.newPassword2 = null;
            sessionStorage.passwordChangeRequired = 'false';
            $scope.passwordChangeRequired = false;
            $scope.passwordValid = true;

            mtzProfileSvc.getSysUserId($scope.getSysUserService).then(result => {
              for (var i = 0; i < result.data.length; i++) {
                if (result.data[i].sysUserName === sessionStorage.username) {
                  $scope.sysUserDTO.sysUserId = result.data[i].sysUserId;
                  $scope.sysUserDTO.sysUserName = result.data[i].sysUserName;
                  $scope.sysUserDTO.statusTypeCode = result.data[i].statusTypeCode;
                }
              }
            },
              res => dialogSvc.showError('getSysUserId Failed', res.data[0].message));
            dialogSvc.showMessage('Password changed!');
            //Google Analytics Event Tracking
            let eventAction = 'Password Changed';
            let eventParameters = {
              'event_category': 'Password Changed',
              'event_label': 'Password Successfully Changed'
            };
            googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
            //End Google Analytics Event Tracking
          })
          .catch(err => {
            $scope.account.currentPassword = null;
            $scope.account.newPassword = null;
            $scope.account.newPassword2 = null;
            $scope.passwordChangeRequired = false;
            $scope.passwordValid = false;
            if (err.data) {
              handlePasswordChangeError($scope.sysUserDTO, dialogSvc, err, err.status);
            }
            else {
              dialogSvc.showError(l10nFilter('password-change-failed', null, true), l10nFilter('password-invalid-incorrect-old-password', null, true));
            }
          });
      }
    };

    function handlePasswordChangeError(sysUser, dialogSvc, err, status) {
      let msg;
      switch (status) {
        case 400:
          if (err.data && err.data.length > 0) {
            switch (err.data[0].code) {
              case 'INVALID_HISTORY':
                msg = l10nFilter('password-invalid-history', null, true);
                break;
              case 'INVALID_NOT_CHANGED':
                msg = l10nFilter('password-invalid-not-changed', null, true);
                break;
              case 'INVALID_PATTERN_MATCH':
                msg = l10nFilter('password-invalid-pattern-match', null, true);
                break;
              case 'INVALID_SAME_AS_USERNAME':
                msg = l10nFilter('password-invalid-same-as-username', null, true);
                break;
              case 'INVALID_CONTAINS_USERNAME':
                msg = l10nFilter('password-invalid-contains-username', null, true);
                break;
              default:
                msg = l10nFilter('password-change-failed', null, true);
            }

          } else {
            resetPasswordCount++;
            switch (resetPasswordCount) {
              case 1:
                msg = l10nFilter('password-invalid-incorrect-old-password', null, true);
                break;
              case 2:
                msg = l10nFilter('password-invalid-lock-warning', null, true);
                break;
              case 3:
                msg = '';
                const username = sessionStorage.username;
                const usernamePrefix = username.substr(0, 3);
                let conNumber = '';
                const redirectUrl = $window.location.origin + $window.location.pathname;

                switch (usernamePrefix) {
                  case 'GMI':
                    conNumber = l10nFilter('contact-number-gmi', null, true);
                    break;
                  case 'ACD':
                    conNumber = l10nFilter('contact-number-acd', null, true);
                    break;
                  case 'GMP':
                    conNumber = l10nFilter('contact-number-gmp', null, true);
                    break;
                  case 'OSR':
                    conNumber = l10nFilter('contact-number-osr', null, true);
                    break;
                }
                dialogSvc.showError(l10nFilter('password-change-failed', null, true), l10nFilter('password-change-failed', null, true) +
                  conNumber, "", function () {
                      mtzProfileSvc.lockUserAccount()
                      .success(
                        mtzProfileSvc.logoutForcibly().success(() => {
                          redirectAndLogout(redirectUrl);
                        }));
                  });
            }
          }
          break;
        default:
          msg = l10nFilter('password-change-failed', null, true);
          dialogSvc.showError(l10nFilter('password-change-failed', null, true), err);
      }

      delete sysUser.success;
      sysUser.err = msg;
      dialogSvc.showError(l10nFilter('password-change-failed', null, true), msg);
    }

    function redirectAndLogout(redirectUrl) {
      if (!sessionStorage.loggedIn) return;
      $window.location.href = redirectUrl;
      sessionStorage.clear();
    }

    $scope.saveProfile = () => {
      $scope.disableSaveButton = true;
        mtzProfileSvc.savePersonalInfo($scope.savePaxService, $scope.paxDTO).then(result => {
          $scope.paxDTO = result.data;
        },
          res => dialogSvc.showError('Save Failed', res.data[0].message));

        //first see if the language pax misc record exists for this person
        $scope.previousLanguagePreference = '';
        mtzProfileSvc.getLanguagePreference().then(result => {
          for (var i = 0; i < result.data.items.length; i++) {
            if (result.data.items[i].name === "LANGUAGEPREFERENCE") {
              $scope.previousLanguagePreference = result.data.items[i].data;
              $scope.paxMiscId = result.data.items[i].id;
            }
          }

          if ($scope.paxMiscId && $scope.paxMiscId !== '') {
            //UPDATE PAX MISC RECORD
            if ($scope.languagePreference && $scope.languagePreference !== '') {
              $scope.paxMiscDTO.data = $scope.languagePreference;
              $scope.paxMiscDTO.date = new Date();
              $scope.paxMiscDTO.id = $scope.paxMiscId;
              $scope.paxMiscDTO.name = "LANGUAGEPREFERENCE";
              $scope.paxMiscDTO.ownerId = sessionStorage.paxId;
              $scope.paxMiscDTO.type = "PROFILE";
              mtzProfileSvc.updateLanguagePreference($scope.paxMiscDTO, $scope.paxMiscId).then(() => {
                sessionStorage.removeItem('languagePreference');
                sessionStorage.languagePreference = $scope.languagePreference;
                document.location.reload();
                dialogSvc.showMessage("Language Preference Updated.");
              });
            }
          }
          else {
            //INSERT PAX MISC RECORD
            if ($scope.languagePreference && $scope.languagePreference !== '') {
              $scope.paxMiscDTO.data = $scope.languagePreference;
              $scope.paxMiscDTO.date = new Date();
              $scope.paxMiscDTO.name = "LANGUAGEPREFERENCE";
              $scope.paxMiscDTO.ownerId = sessionStorage.paxId;
              $scope.paxMiscDTO.type = "PROFILE";
              mtzProfileSvc.insertLanguagePreference($scope.paxMiscDTO).then(() => {
                dialogSvc.showMessage("Language Preference Saved.");
              });
            }
          }
          sessionStorage.languagePreference = $scope.languagePreference;
          loadLanguage();
        });

         if ($scope.noAddress === true) {
           $scope.addressDTO.addressTypeCode = 'HOME';
           $scope.addressDTO.countryCode = 'CA';
           $scope.addressDTO.code1Bypass = 'Y';
           mtzProfileSvc.insertAddressInfo($scope.insertAddressService, $scope.addressDTO).then(result => {
             $scope.addressDTO = result.data;
             $scope.noAddress = false;
           },
             res => dialogSvc.showError('Insert Address Failed', res.data[0].message));
         }
         else {
           mtzProfileSvc.saveAddressInfo($scope.saveAddressService, $scope.addressDTO).then(result => {
             $scope.addressDTO = result.data;
           },
             res => dialogSvc.showError('Save Address Failed', res.data[0].message));
         }

        if ($scope.noPhone === true) {
          $scope.phoneDTO.phoneTypeCode = 'HOME';
          mtzProfileSvc.insertPhoneInfo($scope.insertPhoneService, $scope.phoneDTO).then(result => {
            $scope.phoneDTO = result.data;
            $scope.noPhone = false;
          },
            res => dialogSvc.showError('Insert Phone Failed', res.data[0].message));
        }
        else {
          mtzProfileSvc.savePhoneInfo($scope.savePhoneService, $scope.phoneDTO).then(result => {
            $scope.phoneDTO = result.data;
          },
            res => dialogSvc.showError('Save Phone Failed', res.data[0].message));
        }

        if ((!sessionStorage.passwordChangeRequired || sessionStorage.passwordChangeRequired === 'false') && $scope.personalInfoForm.$valid && $scope.companyForm.$valid && $scope.tncForm.$valid) {
          //update sysuser to active
          mtzProfileSvc.getSysUserId($scope.getSysUserService).then(result => {
            for (var i = 0; i < result.data.length; i++) {
              if (result.data[i].sysUserName === sessionStorage.username) {
                $scope.sysUserDTO.sysUserId = result.data[i].sysUserId;
                $scope.sysUserDTO.sysUserName = result.data[i].sysUserName;
                $scope.sysUserDTO.statusTypeCode = 'ACTIVE';

                /* jshint ignore:start */
                mtzProfileSvc.updateSysUser($scope.updateSysUserService, $scope.sysUserDTO).then( () => {
                  console.log('SysUser Record Updated...');
                },
                  res2 => console.log('updateSysUserId Failed', res2.data[0].message));
                /* jshint ignore:end */
              }
            }
          },
            res => console.log('getSysUserId Failed', res.data[0].message));
        }

    };

    $scope.sendEmailValidationCode = () => {
      if ($scope.emailAddressDTO.emailAddress !== $scope.account.newEmail) {
        //check the email domain and block certain domains
        let badEmailFound = false;
        blockedDomains.forEach(entry => {
          if ($scope.account.newEmail.indexOf(entry) > 0) {
            badEmailFound = true;
          }
        });
        if (badEmailFound) {
          dialogSvc.showMessage(l10nFilter('email-domain-blocked', null, true));
        }
        else {
          let newEmailAddressDTO = angular.copy($scope.emailAddressDTO);
          newEmailAddressDTO.emailAddress = $scope.account.newEmail;
          newEmailAddressDTO.emailId = $scope.emailAddressDTO.emailId;
          mtzProfileSvc.sendEmailValidationCode($scope.createEmailValidationCodeService, newEmailAddressDTO).then(
            () => {
              //Google Analytics Event Tracking
              let eventAction = 'Email Validation';
              let eventParameters = {
                'event_category': 'Email Validation',
                'event_label': 'Email Validation Code Sent'
              };
              googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
              //End Google Analytics Event Tracking
              $scope.account.emailValidationPending = true;
              $scope.showEmailValidationBox = true;
            },
            res => dialogSvc.showError(l10nFilter('send-validation-email-error1', null, true), res.data[0].message));
        }
      }
      else {
        dialogSvc.showMessage(l10nFilter('email-validation-failed', null, true));
      }
    };

    $scope.validateEmailCode = () => {
      //call email validation code service
      mtzProfileSvc.validateEmailCode($scope.validateEmailValidationCodeService, $scope.account.newEmail, $scope.account.emailValidationCode).then(
        () => {
          $scope.account.emailValidationPending = false;
          $scope.emailAddressDTO.emailAddress = $scope.account.newEmail;
          $scope.emailAddressDTO.verificationCode = $scope.account.emailValidationCode;
          if ($scope.noEmailAddress === true) {
            $scope.emailAddressDTO.emailTypeCode = 'HOME';
            mtzProfileSvc.insertEmailAddress($scope.insertEmailAddressService, $scope.emailAddressDTO).then(
              () => {
                //Google Analytics Event Tracking
                let eventAction = 'Email Validation';
                let eventParameters = {
                  'event_category': 'Email Validation',
                  'event_label': 'Email Code Validated'
                };
                googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
                //End Google Analytics Event Tracking
                dialogSvc.showMessage(l10nFilter('email-validation-success-text-1', null, true),
                  $scope.account.newEmail + l10nFilter('email-validation-success-text-2', null, true));
                $scope.account.newEmail = '';
                $scope.account.emailValidationCode = '';
                $scope.account.emailValidationPending = false;
                $scope.showEmailValidationBox = false;
                $scope.emailRequired = false;
              },
              res => dialogSvc.showMessage('Error Inserting Email Address', res.data[0].message));

            //Get the paxMiscId for the validation code
            mtzProfileSvc.getEmailVerification($scope.getEmailVerification).then(result => {
              for (var i = 0; i < result.data.items.length; i++) {
                if (result.data.items[i].name === "EMAIL_VERIFICATION" && result.data.items[i].data) {
                  sessionStorage.paxMiscId = result.data.items[i].id;
                }
              }
              //Delete the validation code record using the paxMiscId
              mtzProfileSvc.deleteEmailVerification($scope.deleteEmailVerification).
                catch(err => {
                  dialogSvc.showError('Error', err.data[0].message);
                });
            });
          }
          else {
            mtzProfileSvc.updateEmailAddress($scope.updateEmailAddressService, $scope.emailAddressDTO).then(
              () => {
                dialogSvc.showMessage(l10nFilter('email-validation-success-text-1', null, true),
                  $scope.account.newEmail + l10nFilter('email-validation-success-text-2', null, true));
                $scope.account.newEmail = '';
                $scope.account.emailValidationPending = false;
                $scope.showEmailValidationBox = false;
              },
              res => dialogSvc.showMessage(l10nFilter('email-update-error', null, true), res.data[0].message));
          }
        },
        res => {
          let msg;
          switch (res.data[0].code) {
            case 'INVALID':
              msg = l10nFilter('profile-verification-code-invalid', null, true);
              break;
            case 'EXPIRED':
              msg = l10nFilter('profile-verification-code-expired', null, true);
              break;
            case 'MISSING':
              msg = l10nFilter('profile-verification-code-missing', null, true);
              break;
            default:
              msg = res.data[0].message;
          }
          dialogSvc.showMessage('Error Validating', msg);
          $scope.emailAddressDTO.emailAddress = $scope.emailAddressDTO.emailAddress;
        });
    };

    $scope.validatePassword = () => {
      $scope.passwordValid = false;
      let countNumbers = $scope.account.newPassword;
      let countLetters = '';
      countNumbers = countNumbers.replace(/[^0-9]/g, "").length;
      countLetters = $scope.account.newPassword.length - countNumbers;

      if ($scope.account.newPassword !== $scope.account.newPassword2 ||
        ($scope.account.newPassword && ($scope.account.newPassword.length < 8 || $scope.account.newPassword.length > 20)) ||
        ($scope.account.newPassword2 && ($scope.account.newPassword2.length < 8 || $scope.account.newPassword2.length > 20)) ||
        countNumbers < 2 ||
        countLetters < 2) {
        $scope.passwordValid = false;
      }
      else {
        $scope.passwordValid = true;
      }

      if ($scope.sysUserDTO.statusTypeCode === 'ACTIVE' &&
        !($scope.account.currentPassword && $scope.account.currentPassword.length > 0)) {
        $scope.passwordValid = false;
      }
    };

    $scope.setAsPreferredCompany = (companyId) => {
      //call the set company service - paxid, employementid, employmentroleid, employmentroleDTO

      for (var i = 0; i < $scope.companyDTO.length; i++) {

        $scope.rolesDTO = $scope.companyDTO[i].roles;

        if ($scope.companyDTO[i].id === companyId) {
          $scope.preferredCompany = $scope.companyDTO[i].companyName;
          $scope.rolesDTO[0].preferred = true;
          sessionStorage.preferredLocation = $scope.preferredCompany;
		  $scope.preferredRolesDTO = $scope.rolesDTO;
          //roles is an array but we are trying to set preferred company, not roles...
          //so using the first instance of roles for preferred company status
        }
        else {
          $scope.rolesDTO[0].preferred = false;
        }
        $scope.companyDTO[i].roles = $scope.rolesDTO;
      }

    };

    $scope.updatePreferredCompany = function () {
      mtzProfileSvc.savePreferredCompany($scope.savePreferredCompanyService, $scope.preferredRolesDTO).then(result => {
        dialogSvc.showMessage(l10nFilter('preferred-company-saved', null, true), result);
        //Google Analytics Event Tracking
        let eventAction = 'Preferred Company Updated';
        let eventParameters = {
          'event_category': 'Preferred Company Updated',
          'event_label': 'Preferred Company Has Been Updated'
        };
        googleAnalyticsSvc.callGoogleAnalytics('event', '', '', eventAction, eventParameters);
        //End Google Analytics Event Tracking
      },
        res => dialogSvc.showError(l10nFilter('save-preferred-company-failed', null, true), res.data[0].message));
    };
  }
]);

am.directive('mtzProfile', () => {
  return {
    restrict: 'AE',
    scope: {
      createEmailValidationCodeService: '@',
      validateEmailValidationCodeService: '@',
      changePasswordService: '@',
      getPaxService: '@',
      getPaxAddressService: '@',
      getPaxPhoneService: '@',
      getPaxEmailAddressService: '@',
      getSysUserService: '@',
      updateSysUserService: '@',
      savePaxService: '@',
      saveAddressService: '@',
      insertAddressService: '@',
      savePhoneService: '@',
      insertPhoneService: '@',
      updateEmailAddressService: '@',
      insertEmailAddressService: '@',
      authenticateLogoutService: '@',
      getCompanyService: '@',
      getCompaniesWorkForService: '@',
      savePreferredCompanyService: '@',
      emailUnsubscribeService: '@',
      emailResubscribeService: '@',
      getEmailUnsubscribeService: '@',
      getEmailVerification: '@',
      deleteEmailVerification: '@',
      termsAndConditionsService: '@',
      logOutForciblyService: '@',
      lockUserAccountService: '@'
    },
    controller: 'mtzProfileCtrl',
    templateUrl: 'feature/share/directives/profile.html'
  };
});
